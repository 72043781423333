<template>
	<v-fade-transition hide-on-leave>
		<template v-if="loading || loadingParent">
			<v-skeleton-loader type="card" />
		</template>
		<template v-else>
			<v-card class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
				<v-card-title>
					<span>{{ $t('users.limits') }}</span>
					<v-spacer />
					<UserLimitsForm :loading-parent="loading || loadingParent" />
				</v-card-title>
				<v-card-text>
					<div class="d-flex justify-space-between flex-wrap">
						<v-list>
							<v-subheader>{{ $t('settings.general') }}</v-subheader>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										{{ $t('gdrive.gDriveEmails') }}: {{ user.total.gDriveEmails }}/{{ user.limits.gDriveEmails }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										{{ $t('users.studentProfiles') }}: {{ user.total.studentProfiles }}/{{ user.limits.studentProfiles }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										{{ $t('users.applications') }}: {{ user.total.applications }}/{{ user.limits.applications }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
						<v-list dense v-for="studentProfile in user.studentProfiles" :key="studentProfile.id">
							<v-subheader>{{ studentProfile.email }}</v-subheader>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										{{ $t('universities.universities') }}: {{ studentProfile.total.universities }}/{{ studentProfile.limits.universities }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										{{ $t('faculties.faculties') }}: {{ studentProfile.total.faculties }}/{{ studentProfile.limits.faculties }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										{{ $t('degrees.degrees') }}: {{ studentProfile.total.degrees }}/{{ studentProfile.limits.degrees }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										{{ $t('nav.courses') }}: {{ studentProfile.total.courses }}/{{ studentProfile.limits.courses }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</div>
				</v-card-text>
			</v-card>
		</template>
	</v-fade-transition>
</template>

<script>
import { mapGetters } from 'vuex'
import UserLimitsForm from '@/components/users/UserLimitsForm.vue'

export default {
	name: 'UserLimits',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true
		}
	},
	components: {
		UserLimitsForm
	},
	computed: {
		...mapGetters({
			user: 'users/user'
		})
	},
	created() {
		this.loading = false
	},
	methods: {
		humanTime(timestamp) {
			return this.$moment(timestamp).format('YYYY-MM-DD hh:mm:ss')
		}
	}
}
</script>

<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template v-slot:activator="{ on }">
			<v-btn icon color="primary" v-on="on">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('offers.mainInformation') }}</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-text-field v-model="limits.gDriveEmails" :label="$t('gdrive.gDriveEmails')" :rules="rules.limits" outlined />
					<v-text-field v-model="limits.studentProfiles" :label="$t('users.studentProfiles')" :rules="rules.limits" outlined />
					<v-text-field v-model="limits.applications" :label="$t('users.applications')" :rules="rules.limits" outlined />
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" text @click="switchEditMode()">{{ $t('search.close') }}</v-btn>
				<v-btn color="blue darken-1" text @click="callUpdate()">{{ $t('settings.edit') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UserLimitsForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			editMode: false,
			valid: false,
			limits: {
				gDriveEmails: 0,
				studentProfiles: 0,
				applications: 0
			},
			rules: {
				limits: [(v) => !!v || 'Restriction is required.', (v) => /^\d+$/.test(v) || 'Restriction must be an integer.']
			}
		}
	},
	computed: {
		...mapGetters({
			user: 'users/user'
		})
	},
	created() {
		this.limits = this.user.limits
		this.loading = false
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		callUpdate() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.updateUserInfo({
				gDriveEmails: this.gDriveEmails,
				studentProfiles: this.studentProfiles,
				applications: this.applications
			}).then(() => {
				this.switchEditMode()
				this.loading = false
			})
		},
		...mapActions('users', ['updateUserLimits'])
	}
}
</script>
